"use client";
import { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";

import { updatePayrollNotifyMutation } from "./_graphql";
import { PayrollConfirmModal } from "./PayrollConfirmModal";

type Props = {
  displayPayrollNotifications:
    | {
        displayNotifyId: string | null;
        payrollReportId: string | null;
        finalPaymentAmount: number | null;
      }[]
    | undefined;
};

export function PayrollServerUpdateModal({
  displayPayrollNotifications,
}: Props) {
  const [mutation, { loading }] = useMutation(updatePayrollNotifyMutation);
  // 現在表示中のモーダルのインデックスを管理
  const [currentIndex, setCurrentIndex] = useState(0);
  // モーダルの表示状態を管理
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 初期表示時に最初のモーダルを表示
  useEffect(() => {
    if (displayPayrollNotifications && displayPayrollNotifications.length > 0) {
      setIsModalOpen(true);
    }
  }, [displayPayrollNotifications]);

  // 次のモーダルに進む処理
  const showNextModal = () => {
    if (
      displayPayrollNotifications &&
      currentIndex < displayPayrollNotifications.length - 1
    ) {
      setCurrentIndex((prev) => prev + 1);
      setIsModalOpen(true);
    } else {
      // すべてのモーダルを表示し終わった場合
      setIsModalOpen(false);
    }
  };

  const updateModalStateAndServerState = async () => {
    if (!displayPayrollNotifications) return;

    try {
      await mutation({
        variables: {
          input: {
            displayNotifyId:
              displayPayrollNotifications[currentIndex]?.displayNotifyId || "",
          },
        },
        onError: (error) => {
          console.error("Error updating notification:", error);
        },
        refetchQueries: ["DisplayPayrollNotifications"],
      });

      // 次のモーダルを表示
      showNextModal();
    } catch (error) {
      console.error("Error in updateModalStateAndServerState:", error);
    }
  };

  if (
    !displayPayrollNotifications ||
    displayPayrollNotifications.length === 0
  ) {
    return null;
  }

  return (
    <PayrollConfirmModal
      finalPaymentAmount={
        displayPayrollNotifications[currentIndex]?.finalPaymentAmount || 0
      }
      handleSubmit={updateModalStateAndServerState}
      opened={isModalOpen}
      keySuffix={currentIndex.toString()}
      zIndexOffset={displayPayrollNotifications.length - currentIndex}
      loading={loading}
    />
  );
}
