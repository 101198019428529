"use client";
import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import { usePathname } from "next/navigation";

import { notificationQuery } from "./_graphql";
import { FiveMinWorkReminder } from "./FiveMinWorkReminder";

export function WorkerNotification() {
  const [getNotifications, { data, loading }] = useLazyQuery(notificationQuery);
  const pathname = usePathname();
  const displayed = true;
  useEffect(() => {
    let isSubscribed = true;
    const fetchNotifications = async () => {
      try {
        // コンポーネントがアンマウントされていない場合のみ実行
        if (isSubscribed) {
          await getNotifications();
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
    // クリーンアップ関数
    return () => {
      isSubscribed = false;
    };
  }, [pathname, getNotifications]);
  if (loading) {
    return null;
  }
  return (
    <>
      <FiveMinWorkReminder
        notificationId={data?.viewer?.fiveMinWorkReminderDisplayedId}
      />
    </>
  );
}
