import { graphql } from "@/gql/__generated__";

export const notificationQuery = graphql(`
  query NotificationQuery {
    viewer {
      fiveMinWorkReminderDisplayedId
    }
    displayPayrollNotifications {
      displayNotifyId
      payrollReportId
      finalPaymentAmount
    }
  }
`);

export const displayFiveMinWorkReminderMutation = graphql(`
  mutation DisplayedFiveMinWorkReminderInput(
    $input: DisplayedFiveMinWorkReminderInput!
  ) {
    displayedFiveMinWorkReminder(input: $input) {
      viewer {
        id
      }
      temporaryJobOffer {
        id
      }
    }
  }
`);

export const displayPayrollNotificationsQuery = graphql(`
  query DisplayPayrollNotifications {
    displayPayrollNotifications {
      displayNotifyId
      payrollReportId
      finalPaymentAmount
    }
  }
`);

export const updatePayrollNotifyMutation = graphql(`
  mutation UpdatePayrollNotify($input: UpdatePayrollNotifyInput!) {
    updatePayrollNotify(input: $input) {
      displayNotify {
        displayNotifyId
        finalPaymentAmount
      }
    }
  }
`);
