"use client";
import { Modal, Image } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import html2canvas from "html2canvas";

import { Button, Group, ShareIcon } from "@/components/ui/elements";

import classes from "./_css/PayrollConfirmModal.module.css";

type Props = {
  finalPaymentAmount: number;
  handleSubmit: () => void;
  opened: boolean;
  keySuffix: string;
  zIndexOffset: number;
  loading: boolean;
};

// 最大幅と最大高さ、フッターの高さを定数として定義
const MAX_WIDTH = 391;
const MAX_HEIGHT = 845;
const FOOTER_HEIGHT = 70;

export function PayrollConfirmModal({
  finalPaymentAmount,
  handleSubmit,
  opened,
  keySuffix,
  zIndexOffset,
  loading,
}: Props) {
  const { height, width } = useViewportSize();
  const modalId = `payroll-confirm-modal-${keySuffix}`;
  const footerId = `payroll-confirm-footer-${keySuffix}`;
  const logoImageId = `logo-image-${keySuffix}`;
  const shareButtonId = `share-button-${keySuffix}`;
  const handleShare = async () => {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      try {
        // デバイスのピクセル比を取得
        const devicePixelRatio = window.devicePixelRatio || 1;
        // 実際の要素のサイズを取得
        const modalRect = modalElement.getBoundingClientRect();
        // html2canvasのオプションを調整
        const modalCanvas = await html2canvas(modalElement, {
          width: modalRect.width,
          height: modalRect.height,
          scale: devicePixelRatio,
          useCORS: true,
          imageTimeout: 0,
          onclone: (clonedDoc) => {
            const clonedElement = clonedDoc.getElementById(modalId);
            if (clonedElement) {
              clonedElement.style.transform = "none";
              clonedElement.style.width = `${modalRect.width}px`;
              clonedElement.style.height = `${modalRect.height}px`;
              clonedElement.style.paddingTop = "20px";
              // シェアボタンを取得
              const shareButton = clonedDoc.getElementById(shareButtonId);
              if (shareButton) {
                // シェアボタンを一時的に非表示
                shareButton.style.display = "none";
              }

              // ロゴ画像の特別な処理
              const clonedLogoImg = clonedDoc.getElementById(logoImageId);

              if (clonedLogoImg) {
                clonedLogoImg.style.width = "310px"; // 元のサイズから調整
                clonedLogoImg.style.height = "75px"; // 元のサイズから調整
                clonedLogoImg.style.objectFit = "contain";
                clonedLogoImg.style.transform = "none";
                clonedLogoImg.style.maxWidth = "100%";
                clonedLogoImg.style.maxHeight = "100%";
                clonedLogoImg.style.transformOrigin = "top left";
              }
            }
          },
        });

        // キャンバスをBlobに変換
        const blobData = await new Promise<Blob>((resolve) => {
          modalCanvas.toBlob(
            (blob) => {
              if (blob) resolve(blob);
            },
            "image/png",
            1.0
          ); // 品質を1.0に設定
        });

        // Blobからファイルを作成
        const file = new File([blobData], "payroll-confirmation.png", {
          type: "image/png",
        });

        // シェア可能かチェック
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
            title: "報酬が確定しました！",
            text: `報酬額：￥${finalPaymentAmount?.toLocaleString()}`,
            url: "https://worker.care-link.website/",
          });
        } else {
          console.log(
            "Web Share APIがサポートされていないか、ファイルの共有ができません。"
          );
        }
      } catch (error) {
        console.error("シェアに失敗しました:", error);
      }
    }
  };
  const isFullscreen = width < MAX_WIDTH || height < MAX_HEIGHT;

  return (
    <Modal
      withCloseButton={false}
      onClose={() => {}}
      classNames={{
        root: classes.root,
        content: classes.modalContent,
      }}
      styles={{
        body: {
          padding: 0,
          height: "100%",
          maxHeight: MAX_HEIGHT,
          display: "flex",
          flexDirection: "column",
        },
      }}
      opened={opened}
      zIndex={300 + zIndexOffset}
      size={MAX_WIDTH}
      mah={MAX_HEIGHT}
      fullScreen={isFullscreen} // iphone 12pro以下は全画面
      centered
    >
      <div
        className={isFullscreen ? classes.modalBodyFull : classes.modalBody}
        id={modalId}
        style={{ flex: 1, overflow: "auto", paddingTop: 10 }}
      >
        <div className={classes.logo}>
          <Image
            id={logoImageId}
            src={"/images/confirm_modal/logo.png"}
            alt={"ケアリンク"}
            w={312}
            h={102}
            fit="contain"
            style={{
              width: "312px",
              height: "102px",
              objectFit: "contain",
              transform: "none",
            }}
          />
        </div>

        <div className={classes.main}>
          <div className={classes.money}>
            <p className={classes.text}>報酬が確定しました！</p>
            <Image
              src={"/images/confirm_modal/money.png"}
              alt={"報酬"}
              fit="contain"
              w={156}
              h={192}
            />
          </div>

          <p className={classes.amountText}>
            ￥{finalPaymentAmount?.toLocaleString()}
          </p>
        </div>
        <Button
          id={shareButtonId}
          size="sm"
          pos="absolute"
          left={15}
          bottom={15}
          onClick={handleShare}
          color="black"
          leftSection={<ShareIcon />}
        >
          シェアする
        </Button>
      </div>
      <Group justify="center" h={FOOTER_HEIGHT} bg="white" id={footerId}>
        <Button
          color="cyan"
          onClick={handleSubmit}
          loading={loading}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          確認
        </Button>
      </Group>
    </Modal>
  );
}
