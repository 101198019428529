import { useEffect } from "react";

import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";
import { useRouter } from "next/navigation";

import { Button, Group, Stack, Text } from "@/components/ui/elements";

import { showNotification } from "@/util/userNotifications";

import { displayFiveMinWorkReminderMutation } from "./_graphql";

type Props = {
  notificationId: string | null | undefined;
};

export function FiveMinWorkReminder({ notificationId }: Props) {
  const [mutation] = useMutation(displayFiveMinWorkReminderMutation);

  const router = useRouter();
  const handleComplete = async (isMoveToWorkSchedule: boolean) => {
    if (!notificationId) return;
    await mutation({
      variables: {
        input: {
          notificationId: notificationId,
        },
      },
      onError: (error) => {
        console.error(error);
      },
      onCompleted: (data) => {
        const res = data;
        if (!res) return;
        const temporaryJobOfferId =
          res.displayedFiveMinWorkReminder?.temporaryJobOffer?.id;
        if (isMoveToWorkSchedule) {
          router.push(`/workSchedule/${temporaryJobOfferId}`);
        }
      },
    });
  };
  const handleClose = async (isMoveToWorkSchedule: boolean) => {
    try {
      await Promise.all([
        notifications.hide("info-notification"), // 通知を非表示
        handleComplete(isMoveToWorkSchedule), // 完了処理
      ]);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };

  useEffect(() => {
    if (!notificationId) return;

    showNotification({
      id: "info-notification",
      type: "info",
      title: "勤務開始時刻5分前になりました。",
      autoClose: false,
      withCloseButton: true,
      message: (
        <Stack gap={0}>
          <Text fz="sm">
            勤務詳細からQRコードを読み込んで勤務を開始してください。
          </Text>
          <Text fz="sm">
            ※ うまく読み込めない場合は手動で出退勤から開始してください。
          </Text>
          <Group mt={5}>
            <Button size="xs" color="gray" onClick={() => handleClose(false)}>
              閉じる
            </Button>
            <Button size="xs" onClick={() => handleClose(true)}>
              勤務詳細
            </Button>
          </Group>
        </Stack>
      ),
    });
  }, [notificationId]);
  return null;
}
