import { MantineSize } from "@mantine/core";
import Link from "next/link";

import { Button } from "@/components/ui/elements";

type Props = {
  latitude: number;
  longitude: number;
  officeName: string;
  address: string;
  size?: MantineSize;
};

export function GoogleMapButton({
  latitude,
  longitude,
  officeName,
  address,
  size = "sm",
}: Props) {
  const encodedOfficeName = encodeURIComponent(officeName);
  const encodedAddress = encodeURIComponent(address);
  return (
    <Button
      component={Link}
      href={`https://www.google.com/maps/search/${encodedAddress}/@${latitude},${longitude},30z`}
      target="_blank"
      variant="outline"
      size={size}
    >
      Google Mapsで開く
    </Button>
  );
}
