import { graphql } from "@/gql/__generated__";

export const notificationQuery = graphql(`
  query NotificationQuery {
    viewer {
      fiveMinWorkReminderDisplayedId
    }
  }
`);

export const displayFiveMinWorkReminderMutation = graphql(`
  mutation DisplayedFiveMinWorkReminderInput(
    $input: DisplayedFiveMinWorkReminderInput!
  ) {
    displayedFiveMinWorkReminder(input: $input) {
      viewer {
        id
      }
      temporaryJobOffer {
        id
      }
    }
  }
`);
