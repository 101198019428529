import { UAParser } from "ua-parser-js";

export function createObjectFromArray<T extends Record<string, unknown>[]>(
  arr: T,
  keys: string[],
) {
  const result: Record<string, unknown> = {} as any;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    result[key] = "";
  }
  return result;
}

export function generateRandomAlphaNumeric(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

// export function encodeImageToBase64(file: File | string): Promise<string> {
//   return new Promise((resolve, reject) => {
//     if (!file) {
//       reject("ファイルが指定されていません。");
//       return;
//     }
//     const reader = new FileReader();
//     if (file instanceof File) {
//       // ファイルの読み込みが完了した時の処理
//       reader.onload = function () {
//         const result = reader.result as string; // reader.resultを文字列にキャスト
//         const base64String = result.split(",")[1];
//         if (base64String) {
//           resolve(base64String); // Base64の文字列を解決(resolve)する
//         } else {
//           reject("Base64エンコードに失敗しました。");
//         }
//       };
//       // ファイルの読み込みを開始
//       return reader.readAsDataURL(file);
//     }
//   });
// }

export function encodeImageToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("ファイルが指定されていません。");
      return;
    }

    const reader = new FileReader();

    // ファイルの読み込みが完了した時の処理
    reader.onload = function () {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("Base64エンコードに失敗しました。");
      }
    };

    reader.readAsDataURL(file); // ファイルの場合は読み込んでBase64エンコード
  });
}

export function parseUserAgent(userAgent: string) {
  const { browser, os, device } = UAParser(userAgent);

  return {
    browser: `${browser.name} ${browser.version}`,
    os: `${os.name} ${os.version}`,
    device: `${device.vendor || ""} ${device.model || ""} ${device.type || "不明"}`,
  };
}
