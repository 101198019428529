import { Text } from "@mantine/core";

import classes from "./_css/DateItem.module.css";

type Props = {
  date: string;
  dayWeek: string;
  isSelected: boolean;
};

export function DateItem({ date, dayWeek, isSelected }: Props) {
  const isToday = date === "本日";
  return (
    <div data-selected={isSelected || undefined} className={classes.dateItem}>
      <Text fz={isToday ? "md" : "lg"} fw={400} pt={isToday ? 7 : 5}>
        {date}
      </Text>
      <Text fz="sm" c="gray" pb={5}>
        {dayWeek}
      </Text>
    </div>
  );
}
